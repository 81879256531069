/* Write your own custom base styles here */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Write your own custom component styles here */

[type='checkbox'] {
  position: absolute;
  left: -9999px;
}
[type='checkbox']:checked ~ .checkbox-box::after {
  content: '✓';
}

/* Per design, this will make spacing between bullet point and text on terms page list
less than what is avaiable with list-style options */
.detail-list li::before {
  background-color: #0f2233;
  border-radius: 0.1625rem;
  content: '';
  display: inline-block;
  min-height: 0.325em;
  min-width: 0.325rem;
  margin-right: 0.325rem;
  vertical-align: middle;
}
